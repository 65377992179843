import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'

// Registrar plugins
// registerPlugin(FilePondPluginImagePreview);
const FilePond = vueFilePond(/* plugins: FilePondPluginImagePreview, etc. */)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FilePond', FilePond)
})
