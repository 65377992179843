export const useAutenticacaoStore = defineStore('autenticacaoStore', {
  state: () => ({
    token: '',
    modulos: [],
  }),
  actions: {
    armazenarToken (token: string) {
      this.token = token
    },
    limparToken () {
      this.token = ''
    },
    async listarModulos () {
      this.modulos = await useApiStore().request('/autenticacao/modulos')
    },
  },
  getters: {
    retornarToken (): string {
      return this.token ?? ''
    },
  },
  persist: {
    paths: ['token', 'modulos'],
  },
})
